import {
  Addresses,
  Agreements,
  BookPayment,
  ConfirmBooked,
  ConfirmLongDistance,
  ConfirmNonCoreGeo,
  // ConfirmUserProvided,
  ConfirmVendorLead,
  LongDistance,
  MoveDate,
  MoveSize,
  MoveSummary,
  MovingCompanyLeads,
  MovingStops,
  SpecialItems,
  ViewQuotes,
} from './components';

export const screens = {

  // Used in all flows
  'date': {
    slug: 'date',
    label: 'Moving date',
    title: 'When will you be moving?',
    isCompleted: (taskable, task) => {
      const requestedDate = task.getMoverBooking(taskable)?.requested_pickup_date;
      return requestedDate && task.canBookDate(requestedDate);
    },
    component: MoveDate,
  },

  // Used in traditional rates flow
  'address': {
    slug: 'address',
    label: 'Addresses',
    title: 'Where will your move start and finish?',
    subtitle: 'Enter your move distance so we can surface the eligible moving options.',
    maxWidth: 480,
    isCompleted: (taskable, task) => (task.getMoverBooking(taskable)?.stops ?? []).length >= 2,
    component: Addresses,
  },

  // Used in vendor lead flow and storage flow
  'moving-stops': {
    slug: 'address',
    title: 'Where will your move start and finish?',
    subtitle: 'Enter your move distance so we can surface the eligible moving options.',
    label: 'Addresses',
    maxWidth: 480,
    isCompleted: (taskable, task) => (task.getMoverBooking(taskable)?.stops ?? []).length >= 2,
    component: MovingStops,
  },

  // Used in traditional rates flow only non storage partners
  'move-size': {
    slug: 'move-size',
    label: 'Move size',
    title: 'How big is your current space?',
    subtitle: 'Understanding the general size of your move helps us pair you with the right pre-approved moving option.',
    isCompleted: (taskable, task) => task.getMoverBooking(taskable)?.number_of_rooms != null,
    component: MoveSize,
  },

  // Used in traditional rates flow
  'special': {
    slug: 'special',
    label: 'Special items',
    title: 'Are you moving any of these?',
    subtitle: (
      <span>
        The below require special handling when being moved into the property.<br />
        Your movers will need to know this to ensure they are equipped for the job.
      </span>
    ),
    isCompleted: (taskable, task) => task.getMoverBooking(taskable)?.special_items != null,
    component: SpecialItems,
  },

  // Used in traditional rates flow
  'moving-quotes': {
    slug: 'moving-quotes',
    component: ViewQuotes,
    label: 'Moving quotes',
    title: 'Here are your moving options',
    subtitle: 'The below options are based on your move details. These hourly rate quotes are fulfilled by pre-approved, top-tier companies in the Moved network. Learn more about Moved Certified movers below.',
    isCompleted: (taskable, task) => task.getMoverBooking(taskable)?.selected_partner_rate != null,
    showOptOut: false, // this is overridden in the moving plan flow
    maxWidth: 1060,
  },

  // Used in traditional rates flow
  'moving-summary': {
    slug: 'summary',
    component: MoveSummary,
    label: 'Summary',
    title: 'Summary',
    maxWidth: 1060,
  },

  // Used in traditional rates flow
  'agreements': {
    slug: 'agreements',
    component: Agreements,
    label: 'Agreements',
    title: 'You must agree to the following terms and conditions',
    isCompleted: (taskable, task) => task.getMoverBooking(taskable)?.terms_accepted_at != null,
    maxWidth: 800,
  },

  // Used in traditional rates flow
  'moving-payment': {
    slug: 'payment',
    component: BookPayment,
    label: 'Payment',
    title: 'Payment information',
    subtitle: 'Enter your credit card information below.',
    isCompleted: (taskable, task) => task.getMoverBooking(taskable)?.booked_service_order != null,
    maxWidth: 1060,
  },

  // Used in vendor lead flow
  'moving-company-preference': {
    slug: 'moving-companies',
    component: MovingCompanyLeads,
    label: 'Moving companies',
    title: 'Get a quote from a top-tier Moving Company',
    subtitle: `Get an exclusive discounted quote from a best-in-class local
      moving company based on your specific move details. No commitments.
      It couldn’t be easier.`,
    isCompleted: (taskable) => taskable?.selected_moving_company_ads != null && taskable?.completed_at != null,
  },

  // Used in traditional rates flow
  'confirmation-book-movers': {
    slug: 'confirmation',
    component: ConfirmBooked,
    label: 'Confirmation',
    maxWidth: 1060,
    backRestricted: true,
    isCompleted: (taskable, task) => task.isBooked(task.getMoverBooking(taskable)),
  },

  // Used in vendor lead flow
  'confirmation-vendor-lead': {
    slug: 'confirmation',
    component: ConfirmVendorLead,
    label: 'Confirmation',
    title: 'Your moving company recommendation',
    backRestricted: true,
    isCompleted: (taskable) => taskable?.contact_email != null && taskable?.contact_phone != null,
  },

  // Used in all flows (overridden in moving-plan to allow opt-out)
  'long-distance': {
    slug: 'long-distance',
    component: LongDistance,
    label: "Long distance",
    title: 'It looks like you are moving long distance!',
    maxWidth: 1060,
    isCompleted: (taskable) => taskable?.contact_email != null && taskable?.contact_phone != null,
  },

  // Used in all flows
  'confirmation-long-distance': {
    slug: 'confirmation',
    component: ConfirmLongDistance,
    label: 'Confirmation',
    backRestricted: true,
    isCompleted: (taskable, task) => task.isBooked(task.getMoverBooking(taskable)),
  },

  // Used in all flows
  'confirmation-non-core-geo': {
    slug: 'confirmation',
    component: ConfirmNonCoreGeo,
    label: 'Confirmation',
    backRestricted: true,
    isCompleted: () => true, // the presence of this screen means that it is complete
  },

};
