import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import * as Yup from 'yup';
import { get } from 'lodash';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useNotify, useUser, format, globals } from '@moved/services';

import { OtherVehicles } from '.';
import { ScreenTitle } from '../../shared';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';

// Multi-file document upload screen component
export const VehicleDetails = ({ screen, nextScreen, taskDefinition, taskDetails }) => {
  // HOOKS
  const { id, screenContext } = useParams();
  const { user } = useUser();
  const dispatch = useDispatch();
  const Notify = useNotify();

  // REDUX
  const taskable = taskDefinition.selectors.useTaskable(id);
  const vehicleTypes = taskDefinition.selectors.useVehicleTypes();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskable);
  const activeVehicle = taskDefinition.getActiveVehicle(taskable, screenContext);
  const createPending = taskDefinition.selectors.useCreateVehiclePending();
  const vehiclePending = taskDefinition.selectors.useUpdateVehiclePending();
  const loading = taskDefinition.selectors.useVehicleRegistrationPending();
  const typesPending = taskDefinition.selectors.useVehicleTypesPending();
  const vehicles = get(activeRequest,'vehicles', []);

  const pending = createPending || vehiclePending;

  useEffect(() => {
    dispatch(taskDefinition.actions.getVehicleTypes())
      .catch(error => Notify.error(format.error(error)));
  },[taskDefinition]); // eslint-disable-line

  const fields = [
    {
      type: 'itemList',
      name: 'vehicle_type_id',
      label: 'Which type of vehicle would you like to register?',
      hiddenLabel: true,
      isSquare: true,
      required: 'Vehicle type is required.',
      value: get(vehicleTypes.find(type => type.name === get(activeVehicle,'vehicle_type')),'id'),
      isTwoCol: true,
      options: vehicleTypes.map(type => ({
        label: type.name,
        value: type.id,
        icon: { symbol: format.capitalize(type.icon), library: 'vehicles'},
      })),
    },
    {
      type: 'text',
      name: 'make',
      label: 'Make',
      required: true,
      half: true,
      value: get(activeVehicle,'make'),
    },
    {
      type: 'text',
      name: 'model',
      label: 'Model',
      required: true,
      half: true,
      value: get(activeVehicle,'model'),
    },
    {
      type: 'text',
      name: 'year',
      label: 'Year',
      half: true,
      maxlength: 4,
      flavor: 'YYYY',
      value: get(activeVehicle,'year'),
    },
    {
      type: 'text',
      name: 'color',
      label: 'Color',
      required: true,
      half: true,
      value: get(activeVehicle,'color'),
    },
    {
      type: 'text',
      name: 'license_plate_number',
      label: 'License plate number',
      required: true,
      half: true,
      maxlength: 8,
      value: get(activeVehicle,'license_plate_number'),
    },
    {
      type: 'select',
      name: 'state',
      label: 'State',
      required: true,
      half: true,
      value: get(activeVehicle,'state'),
      options: globals.stateOptions,
    },
    {
      type: 'select',
      name: 'owner_tenant_id',
      label: 'Owner',
      required: true,
      options:  taskable.tenants_on_lease.map(tenant => ({
        label: format.fullname(tenant),
        value: tenant.id,
      })),
      value: (
        get(activeVehicle,'owner.id')
        || get(taskable,'tenants_on_lease').find(tenant => tenant.user_id === user.id).id
      ),
    },
  ];

  const validation = Yup.object().shape({
    year: Yup.date('Must be a valid year.').required('Year is required.').min('1900', 'Must be a valid year.').max(new Date().getFullYear(), 'Cannot be later than the current year.'),
  });

  const _handleSubmit = formData => {
    if(pending) return false;

    // Create vehicle helper
    const createOrUpdateVehicle = request => {
      return activeVehicle ?
        dispatch(taskDefinition.actions.updateVehicle(request.id, activeVehicle.id, formData)) :
        dispatch(taskDefinition.actions.createVehicle(request.id, formData));
    };

    // Create request if none exists or use existing
    (activeRequest?.status === 'draft' ?
      new Promise(resolve => resolve(activeRequest)) :
      dispatch(taskDefinition.actions.createRequest(id))
        .then(resp => taskDefinition.helpers.getActiveRequest(resp))
    )
      .then(resp => createOrUpdateVehicle(resp))
      .then(() => dispatch(taskDefinition.actions.update(id, { has_vehicles: true, })))
      .then(nextScreen)
      .catch(error => Notify.error(format.error(error)));
  };

  const _handleNoVehicles = () => {
    if(pending) return false;
    return dispatch(taskDefinition.actions.update(id, { has_vehicles: false, }))
  }

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />

        <OtherVehicles taskDetails={taskDetails} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              { (!loading && !typesPending) ? (
                <DynamicForm
                  id={`${screen.slug}-${screen.context}-form`}
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={pending}
                  validation={validation}
                />
              ) : (
                <AtomSpinner />
              )}
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_choice}>
            {vehicles.length < 1 && (
              <label
                tabIndex="1"
                role="button"
                className={'btn-gray' + (pending  ? ' loading' : '')}
                disabled={pending}
                onClick={e => { e.preventDefault(); return _handleNoVehicles()}}
              >
                Nothing to register
              </label>
            )}
            <label
              htmlFor={`${screen.slug}-${screen.context}-form-submit`}
              tabIndex="0"
              role="button"
              className={'btn-primary' + (pending  ? ' loading' : '')}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>

      </div>
    </div>
  );
};
