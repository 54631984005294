import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams,useHistory } from "react-router-dom";
import classNames from 'classnames';

import { Icon, PopOver, ConfirmationModal, AtomSpinner } from '@moved/ui';
import { useNotify, format, useModal } from '@moved/services';

import { ScreenTitle } from '../../shared';
import { OtherPets } from './';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/PetsSummary.module.scss';

const PetCard = ({ pet, activeRequest, taskDefinition, taskBaseRoute }) => {
  const history = useHistory();
  const { id } = useParams();
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // Remove pet details handler
  const _handleDeletePetDetails = (pet, pets) => {

    // check if pet to be removed is the only pet
    if(!(pets.length > 1)) {
      return Promise.all([ dispatch(taskDefinition.actions.cancelPetDetails(activeRequest.id, pet.id)), dispatch(taskDefinition.actions.update(id, { has_pets: false })) ])
      .then((response) => Notify.default(`Details for ${pet.name} have been removed.`))
      .catch(error => Notify.error(format.error(error)));
    }

    return dispatch(taskDefinition.actions.cancelPetDetails(activeRequest.id, pet.id))
      .then((response) => Notify.default(`Details for ${pet.name} have been removed.`))
      .catch(error => Notify.error(format.error(error)));
  }


  // remove pet details confirmation modal
  const _openRemoveModal = (petDetails, pets) => Modal.open(
    <ConfirmationModal
      title={`Cancel registration for ${petDetails.name}`}
      copy={(
        <>
          Are you sure you wish to delete all details for <strong>{petDetails.name}</strong> and withdraw {petDetails.gender ? (petDetails.gender === 'female'? 'her': 'his' ) : 'their'} registration?
          This action cannot be undone.
        </>
      )}
      confirmText='Confirm'
      cancelText='Never mind'
      onConfirm={() => _handleDeletePetDetails(pet, pets)}
    />,
    { sondheim: true },
  );

  return (
    <div className={CSS.card}>
      <div className={CSS.popover}>
        <PopOver
          id={`more_${pet.id}`}
          customClass={CSS.pet_popover}
          className={classNames(CSS.icon,CSS.icon_more)}
          hideArrow="true"
          placement="bottom-end"
          trigger="click"
          delayHide={400}
          tooltip={(
            <div id={'popover_more_options'}>
              <div
                className={CSS.popover_action}
                onClick={e => {
                  e.preventDefault();
                  history.push(`${taskBaseRoute}/${id}/type/${pet.id}`);
                }}
              >
                <Icon symbol={'Edit'} library={'general'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Edit</span>
              </div>
              <div className={classNames(CSS.popover_action, CSS.delete)} onClick={e => _openRemoveModal(pet, activeRequest.pets)}>
                <Icon symbol={'Close'} library={'navigation'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Cancel registration</span>
              </div>
            </div>
          )}
          stopPropagation
        >
          {(tooltipShown) => (
            <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
          )}
        </PopOver>
      </div>
      <div className={CSS.circle} />
      <h3 className={CSS.name}>{pet.name}</h3>
      <p className={CSS.breed}>{pet.breed}</p>
      {pet.photo_url
        ? (<div className={CSS.photo} style={{backgroundImage:`url(${pet.photo_url})`}} />)
        : (
          <div className={CSS.pet_icon}>
            <div className={CSS.spacer} />
            <Icon
              symbol={format.capitalize(pet.pet_type)}
              library={'animals'}
              size={'65%'}
            />
          </div>
        )}
    </div>
  );
};


export const PetsSummary = ({ screen, nextScreen, taskBaseRoute, taskDefinition, taskDetails }) => {
  // HOOKS
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const Notify = useNotify();

  // REDUX
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const updatePending = taskDefinition.selectors.usePetRegistrationPending();
  const requestPending = taskDefinition.selectors.useUpdatePetRequestPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();

  // STATE
  const pending = updatePending || requestPending;

  const _addPet = () => {
    dispatch(taskDefinition.actions.createPet(activeRequest.id, {}))
    .then(() => history.push(`${taskBaseRoute}/${id}`))
    .catch(error => Notify.error(format.error(error)));
  };

  const _declinePets = e => {
    e.preventDefault();

    return dispatch(taskDefinition.actions.update(id, { 'has_pets': false }))
      .then(nextScreen)
      .catch(error => Notify.error(format.error(error)));
  };

  const _handleSubmit = e => {
    e.preventDefault();
    return dispatch(taskDefinition.actions.submitRequest(activeRequest.id))
      .then(nextScreen)
      .catch(error => Notify.error(format.error(error)));

  };

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />

        <OtherPets taskDetails={taskDetails} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            {loading ? (
              <AtomSpinner />
            ) : (
              <div className={CSS.card_holder}>
              {activeRequest.pets.map(pet => (
                <PetCard
                  key={pet.id}
                  pet={pet}
                  taskDefinition={taskDefinition}
                  taskBaseRoute={taskBaseRoute}
                  activeRequest={activeRequest}
                />
              ))}
              <div className={CSS.add} onClick={_addPet}>
                <div className={CSS.add_button}>
                  <Icon symbol={'Plus'} library={'navigation'} size={'24px'} className={CSS.add_icon} />
                </div>
                <div className={CSS.add_text}>Add another pet or service animal</div>
              </div>
            </div>
            )}
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            {activeRequest.pets.length > 0
              ? (
                <button
                  onClick={_handleSubmit}
                  tabIndex="0"
                  className={classNames('btn-primary btn--full',{ loading: pending })}
                  disabled={pending}
                >
                  Submit
                </button>
              )
              : (
                <button
                  className={classNames('btn-ghost btn--full',{ loading: pending })}
                  onClick={_declinePets}
                  disabled={pending}
                >
                  No additional pets
                </button>
              )
            }
          </div>
        </div>

      </div>
    </div>
  );
};
