import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { Transitions, Greeting } from '@moved/ui';
import { useUser, useMobile } from '@moved/services';

import { LayoutSidebar, SplashBG } from '../../common';
import { useMove } from '../../moves/actions/selectors';
import { getMoveNavigation } from '../../moves/helpers/navigation';

import { getMarketplaceAds } from '../actions';
import { useMarketplaceAds } from '../actions/selectors';

import { SponsoredAds } from './SponsoredAds';
import { MarketplaceAds } from './MarketplaceAds';

import CSS from './styles/Marketplace.module.scss';

export const Marketplace = () => {
  const dispatch = useDispatch();
  const { moveId } = useParams();
  const { user } = useUser();
  const isMobile = useMobile();
  const move = useMove(moveId);
  const moveStep = get(move,'move_steps[0]');
  // we want the partner, but it's not available, so use the building as a proxy
  const moveStepBuilding = moveStep && get(moveStep,'building');

  const marketplaceAds = useMarketplaceAds(move.id) || [];
  const movedAds = marketplaceAds.find(group => get(group,'sponsor_partner.name') === 'Moved');
  const partnerAds = marketplaceAds.find(group => get(group,'sponsor_partner.name') !== 'Moved');

  useEffect(() => {
    dispatch(getMarketplaceAds(move.id));
  },[]); // eslint-disable-line

  return (
    <LayoutSidebar className={CSS.layout_wrapper} navigation={getMoveNavigation(move)} withParallax>
      <Helmet>
        <title>{`Welcome to Moved`}</title>
      </Helmet>

      <SplashBG
        background={isMobile && `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-landscape.jpg`}
        video={!isMobile && `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-landscape.mp4`}
        className={CSS.splash}
      >
        { moveStep ? (
          <div>
            { moveStepBuilding && (
              <div className={CSS.building_logo}>
                <img src={moveStepBuilding.logo_url} alt={moveStepBuilding.name} />
              </div>
            )}
            <div className={CSS.greeting}>Moving Offers</div>
          </div>
        ) : (
          <Greeting user={user} className={CSS.greeting}/>
        )}
      </SplashBG>

      <section id='dashboard' className={CSS.dashboard}>
        <div className={CSS.transition_wrapper}>
          <Transitions pageKey={'marketplace'} transition={'fade'}>
            <div className={CSS.tasks_section}>
              <div className='container'>

                <SponsoredAds adCollection={partnerAds} move={move} />

                <MarketplaceAds adCollection={movedAds} move={move} />

              </div>
            </div>
          </Transitions>
        </div>
      </section>

    </LayoutSidebar>
  );
}
