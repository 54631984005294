import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { bookMovers } from '@moved/product';
import { CompleteCard, FieldList } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions,
} from '../../shared';

export const ConfirmNonCoreGeo = ({screen, taskDetails, taskDefinition, moveId, origin, goToScreen, onClose }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const updateTaskPending = taskDefinition.selectors.useUpdateMoverBookingTaskPending();
  const resetPending = taskDefinition.selectors.useResetMoverBookingTaskPending();
  const pending = updateTaskPending || resetPending;

  const _changeSelection = () => {
    // Revert mover_booking data
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, {
      requested_pickup_date: null,
      stops: [],
      contact_email: null,
    }))
      // then reset the task
      .then(r => taskDefinition.constructor.name !== 'BookMovers' ? dispatch(taskDefinition.actions.reset(taskDetails.id)) : r)
      // refresh the move because reset affects MBT and MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => Notify.error(format.error(err)));
  };

  const fields = [
    ...bookMovers.getSummaryFields(moverBooking),
    {
      value: (
        <div
          className={classNames('btn-gray btn--small',{'loading':pending})}
          disabled={pending}
          onClick={_changeSelection}
        >
          Start over
        </div>
      )
    },
  ];

  const flavor = (
    <Snippet tag={'tasks.book-movers.summary.non-core-geo'}>
      We don't have moving partners in your location quite yet. If your final destination changes, please check back to see if we can help!
    </Snippet>
  );

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
            <CompleteCard
              icon={{ symbol: 'Marker#1', color: 'orange'}}
              title='Sorry – no supported movers in your area.'
              flavor={flavor}
            >
              <FieldList list={fields} />
            </CompleteCard>
      </ScreenContent>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </ScreenLayout>
  )
};
