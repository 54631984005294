import { get } from 'lodash';

import {
  AccountDetails,
  GridRewardsScreen,
  PublicGridScreen,
  SelectProvider,
  UtilitySetupSummary,
} from './components'

export const screens = {

  'select-provider': {
    slug: 'provider',
    label: 'Set up account',
    title: 'Set up your utility provider',
    subtitle: 'Be sure to set up your account prior to your move to ensure continuity of service.',
    component: SelectProvider,
    isCompleted: (data, { helpers }) => get(helpers.getActiveRequest(data),'utility_provider.id'),
    maxWidth: 710,
  },
  'account-details': {
    slug: 'details',
    label: 'Account details',
    title: 'Provide proof of account setup',
    subtitle: (<>We require a utility account set up in a leaseholder&rsquo;s name prior to move-in.</>),
    component: AccountDetails,
    isCompleted: (data, { helpers }) => {
      const {
        account_number_required,
        confirmation_number_required,
        upload_required,
        gridrewards_enabled,
        gridrewards_fullscreen
      } = data.settings;
      const activeRequest = helpers.getActiveRequest(data);
      if(gridrewards_enabled && gridrewards_fullscreen) {
        return (!account_number_required || get(activeRequest,'account_number'))
          && (!confirmation_number_required || get(activeRequest,'confirmation_number'))
          && (!upload_required || get(activeRequest,'file_url'));
      } else {
        return activeRequest && get(activeRequest,'status') !== 'draft';
      }
    },
    maxWidth: 592,
  },

  'gridrewards': {
    slug: 'gridrewards',
    label: 'Rewards',
    title: 'Sign up for GridRewards',
    component: GridRewardsScreen,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest && get(activeRequest,'status') !== 'draft';
    },
  },

  'public-grid': {
    slug: 'public-grid',
    label: 'Public Grid',
    title: 'Sign up for Public Grid',
    component: PublicGridScreen,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest && get(activeRequest,'status') !== 'draft';
    },
    maxWidth: 'none',
  },

  'summary': {
    slug: 'summary',
    component: UtilitySetupSummary,
    label: "Summary",
    backRestricted: (data) => get(data,'requests[0].status') !== 'draft',
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest?.status === 'approved';
    },
  },
};
