import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import { Icon, Card, Button, Form, FormAddress } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { updateMove, getMoveSummaries } from '../../actions';
import { useUpdateMovePending } from '../../actions/selectors';
import { useWizard, Content, Actions } from './OnboardingWizard';
import mapGraphic from './images/map-graphic.png';

import CSS from './StepAddresses.module.scss';

// TODO: convert the map bouncing pin to a component in moved-ui

export const StepAddresses = () => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { move, next, prev } = useWizard();

  const pending = useUpdateMovePending();

  const initialOrigin = (
    move?.origin_address ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-out')?.lease?.address // prepopulated from the lease data
  ) ?? {};
  const initialDestination = (
    move?.destination_address ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-in')?.lease?.address // prepopulated from the lease data
  ) ?? {};

  const [isFormValid, setIsFormValid] = useState(!isEmpty(initialOrigin) && !isEmpty(initialDestination));

  const submit = (data) => {
    dispatch(updateMove(move?.id, data))
      .then(() => dispatch(getMoveSummaries())) // refresh move summaries to reflect the new addresses
      .then(() => next())
      .catch(err => Notify.error(format.error(err)));
  };

  return (
    <>
      <Content>

        <div className='stackVertical gap-4'>
          <div className='headingL contentPrimary'>
            Confirm your addresses
          </div>
          <div className='labelM contentSecondary'>
            This helps us understand how far you are moving & what relocation services we may be able to assist you with.
          </div>
        </div>

        <Form
          name={'addresses-form'}
          onSubmit={submit}
          validation={Yup.object({
            origin_address: Yup.object().nullable().required('required'),
            destination_address: Yup.object().nullable().required('required'),
          })}
          onChange={values => setIsFormValid(values?.origin_address && values?.destination_address)}
        >
          <div className={classNames('stackVertical gap-28', CSS.stopsList)}>
            <div className='stackHorizontal gap-24 items-center'>
              <Card className={CSS.stopIcon}>
                <Icon
                  library='map'
                  symbol='Origin'
                />
              </Card>
              <FormAddress
                name='origin_address'
                label='Origin'
                value={initialOrigin}
                className='width-full'
              />
            </div>
            <div className='stackHorizontal gap-24 items-center'>
              <Card className={CSS.stopIcon}>
                <Icon
                  library='map'
                  symbol='Destination'
                />
              </Card>
              <FormAddress
                name='destination_address'
                label='Destination'
                value={initialDestination}
                className='width-full'
              />
            </div>
          </div>
        </Form>

        <Actions>
          <Button
            text='Back'
            icon={{ library: 'navigation', symbol: 'Arrow-left' }}
            onClick={prev}
            color='secondary'
            disabled={pending}
          />
          <Button
            text='Next'
            icon={{ library: 'navigation', symbol: 'Arrow-right' }}
            iconPosition='right'
            form={'addresses-form'}
            disabled={!isFormValid || pending}
          />
        </Actions>

      </Content>

      <div className={CSS.sideImage}>
        <img src={mapGraphic} alt='Dashboard Preview' />
        <div className={CSS.overlay}>
          <div className={CSS.pinShadow} />
          <Icon library='map' symbol='Marker#1' size='48px' className={CSS.pin} />
        </div>
      </div>

    </>
  );
};
