import {
  Instructions,
  Summary,
} from './components';

export const screens = {

  'instructions': {
    slug: 'instructions',
    component: Instructions,
    label: 'Instructions',
    title: 'Complete your move-in inspection',
    subtitle: 'Please ensure that you complete the move-in inspection immediately upon move-in',
    isCompleted: (taskable) => taskable.submitted_at,
  },

  'summary': {
    slug: 'confirmation',
    component: Summary,
    label: 'Confirmation',
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest?.status === 'approved';
    },
  },

};
