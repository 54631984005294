import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';

import { useUser, useModal, useNotify, format, useMobile } from '@moved/services';
import { CustomerSupportEmailLink } from '@moved/product';
import { Notebox, Button } from '@moved/ui';

import { getDashboardRoute } from '../../dashboard/helpers';
import { LayoutCollapsibleHeader } from '../../common';
import { createMove } from '../actions';
import { useMoveSummaries, useCreateMovePending } from '../actions/selectors';
import { MoveSummary } from './MoveSummary';
import { OnboardingWizard } from './OnboardingWizard';

import CSS from './styles/MovesList.module.scss';

const getLatestMoveDate = (move) => get(move,'move_step_summaries.length') > 0
  ? moment.max(get(move,'move_step_summaries',[]).map(step => moment(get(step,'date'),'YYYY-MM-DD')))
  : moment.max(get(move,'mover_bookings',[]).map(booking => moment(get(booking,'requested_pickup_date'),'YYYY-MM-DD')));

const sortByMoveDate = (a,b) => {
  const aSortDate = getLatestMoveDate(a).isValid() ? getLatestMoveDate(a) : moment(get(a,'created_at'));
  const bSortDate = getLatestMoveDate(b).isValid() ? getLatestMoveDate(b) : moment(get(b,'created_at'));
  return bSortDate.diff(aSortDate);
};

export const MovesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Notify = useNotify();
  const Modal = useModal();
  const isMobile = useMobile();
  const moves = useMoveSummaries() || [];
  const createMovePending = useCreateMovePending();
  const { user } = useUser();

  // Split the moves into sections based on the latest move date
  const upcomingMoves = [];
  const pastMoves = [];
  const legacyMoves = [];
  moves.forEach(move => {
    // filter direct app moves
    if(!['resident','storage','consumer'].includes(get(move,'app'))) return legacyMoves.push(move);
    // determine best date to use for moveDate for each move
    const moveDate = getLatestMoveDate(move);
    // if no valid date, or the date is today or in the future, sort into upcoming, else sort into past
    !moveDate.isValid() || moveDate.isSameOrAfter(moment(),'day') ? upcomingMoves.push(move) : pastMoves.push(move);
  });
  // ensure we display them in chronological order
  upcomingMoves.sort(sortByMoveDate);
  pastMoves.sort(sortByMoveDate);

  const createNewMove = () => {
    if(createMovePending) return;
    dispatch(createMove(get(user,'id')))
      .then(move => {
        Notify.default(`New move started. Let's go!`);
        history.push(getDashboardRoute(move?.id));
        // normally we need to detect if the move meets multiple eligibility criteria before launching the wizard
        // but since we know this is always a consumer move, we can skip those checks
        return Modal.open(
          <OnboardingWizard move={move} />,
          { glass: true },
        );
      })
      .catch(error => Notify.error(format.error(error)));
  };

  return (
    <LayoutCollapsibleHeader theme='light'>
      <div className={CSS.dashboard}>
        <div className={CSS.content}>
          <div className={CSS.headliner}>
            <h1>Moves</h1>
            <div className={CSS.actions}>
              <Button
                onClick={createNewMove}
                disabled={createMovePending}
                icon={{ library: 'navigation', symbol: 'Plus' }}
                text={isMobile ? 'New' : 'Start new move'}
                size='large'
              />
            </div>
          </div>
          <div className={CSS.moves}>
            <label>Upcoming</label>
            { upcomingMoves.length > 0 ? upcomingMoves.map((move) => (
              <MoveSummary key={move.id} move={move} />
            )) : <NoResults message='No upcoming moves.'/> }
          </div>
          <div className={CSS.moves}>
            <label>Past</label>
            { pastMoves.length > 0 ? pastMoves.map((move) => (
              <MoveSummary key={move.id} move={move} />
            )) : <NoResults message='No past moves.'/> }
          </div>
          { legacyMoves.length > 0 && (
            <div className={CSS.moves}>
              <Notebox
                icon={{ library: 'general', symbol: 'Binocular'}}
                heading={`Looking for a move that's not here?`}
                body={(
                  <span>
                    Reach out to our customer service team for support via the
                    contact form in the bottom right corner or send us an email
                    at <CustomerSupportEmailLink/>.
                  </span>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </LayoutCollapsibleHeader>
  );
};

const NoResults = ({ message='Nothing to show here.' }) => (
  <div className={CSS.no_results}>{ message }</div>
);
