import { merge } from 'lodash';

import * as taskSummary from './getTaskSummary';

import * as addonInterests from '../types/addon-interests/actions/reducer';
import * as badges from '../types/badges/actions/reducer';
import * as bookMovers from '../types/book-movers/actions/reducer';
// import * as bookMoversStorage from '../types/book-movers-for-storage/actions/reducer';
// import * as bookMoversDirect from '../types/book-movers-for-direct/actions/reducer';
import * as coi from '../types/coi/actions/reducer';
import * as cableInternet from '../types/cable-internet/actions/reducer';
import * as deposit from '../types/deposit/actions/reducer';
import * as documents from '../types/documents/actions/reducer';
// import * as faqsDirect from '../types/faqs-for-direct/actions/reducer';
import * as happycoInspection from '../types/happyco-inspection/actions/reducer';
import * as insurance from '../types/insurance/actions/reducer';
import * as keys from '../types/keys/actions/reducer';
import * as moveOutDetails from '../types/move-out-details/actions/reducer';
import * as movingPlan from '../types/moving-plan/actions/reducer';
import * as orientation from '../types/orientation/actions/reducer';
import * as payMoveInCosts from '../types/pay-move-in-costs/actions/reducer';
import * as petRegistration from '../types/pet-registration/actions/reducer';
import * as petscreeningVerification from '../types/petscreening-verification/actions/reducer';
import * as reserve from '../types/reserve/actions/reducer';
import * as shared from '../types/shared/actions/reducer';
import * as utilities from '../types/utilities/actions/reducer';
import * as utilitySetup from '../types/utility-setup/actions/reducer';
import * as vehicleRegistration from '../types/vehicles/actions/reducer';

export const initialState = merge(
  taskSummary.initialState,
  addonInterests.initialState,
  badges.initialState,
  bookMovers.initialState,
  coi.initialState,
  cableInternet.initialState,
  deposit.initialState,
  documents.initialState,
  // faqsDirect.initialState,
  happycoInspection.initialState,
  insurance.initialState,
  keys.initialState,
  moveOutDetails.initialState,
  movingPlan.initialState,
  orientation.initialState,
  payMoveInCosts.initialState,
  petRegistration.initialState,
  petscreeningVerification.initialState,
  reserve.initialState,
  shared.initialState,
  utilities.initialState,
  utilitySetup.initialState,
  vehicleRegistration.initialState,
);

export const reducers = [
  taskSummary.reducer,
  ...addonInterests.reducers,
  ...badges.reducers,
  ...bookMovers.reducers,
  // ...bookMoversStorage.reducers,
  // ...bookMoversDirect.reducers,
  ...coi.reducers,
  ...cableInternet.reducers,
  ...documents.reducers,
  ...deposit.reducers,
  // ...faqsDirect.reducers,
  ...happycoInspection.reducers,
  ...insurance.reducers,
  ...keys.reducers,
  ...moveOutDetails.reducers,
  ...movingPlan.reducers,
  ...orientation.reducers,
  ...payMoveInCosts.reducers,
  ...petRegistration.reducers,
  ...petscreeningVerification.reducers,
  ...reserve.reducers,
  ...shared.reducers,
  ...utilities.reducers,
  ...utilitySetup.reducers,
  ...vehicleRegistration.reducers,
];
