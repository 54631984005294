import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useNotify, useUser, userService, format } from '@moved/services';
import { LayoutPartnerPromo, DefaultPageNotFound } from '@moved/product';
import { LoaderOverlay, Icon, ContentRenderer } from '@moved/ui';

import { CouponTicket } from './CouponTicket';
import { getLandingPage, getTokenData, claimToken } from '../actions';
import { useLandingPage } from '../actions/selectors';

import CSS from '../styles/LandingPage.module.scss';

export const ApiLandingPage = (props) => {
  const { landingPageSlug, token } = useParams();
  const dispatch = useDispatch();
  const { user, hasDomain, updateUser } = useUser();
  const history = useHistory();
  const Notify = useNotify();
  const [tokenData, setTokenData] = useState();
  const [pending, setPending] = useState(true);

  // redux
  const landingPage = useLandingPage(landingPageSlug);

  useEffect(() => {
    if(!hasDomain('guest')) {
      Notify.default(userService.getDomainBasedGreeting(user));
      return userService.redirect(user,history);
    }
    Promise.all([
      dispatch(getLandingPage(landingPageSlug)),
      dispatch(getTokenData(token)),
    ])
      .then(([landingPage,tokenData]) => {
        // ensure the partner landing matches the token's partner
        if(get(tokenData,'partner.slug') !== get(landingPage,'partner.slug'))
          return history.replace(`/via/${get(tokenData,'partner.slug')}/${token}`);
        setTokenData(tokenData);
        setPending(false);
      })
      .catch(() => {
        Notify.error(`Uh oh. This link is invalid. Please try following the original invitation link again.`);
        history.replace('/signin');
      });
  },[landingPageSlug, token]); // eslint-disable-line

  if(pending) return <LoaderOverlay />;
  if(!landingPage || !tokenData) return <DefaultPageNotFound />;

  const { partner } = landingPage;
  const { location, user:tokenUser, coupon_amount } = tokenData;
  const coupon = coupon_amount && {
    type: 'cents',
    cents: coupon_amount,
  };

  const onSubmit = () => {
    dispatch(claimToken(token))
      .then(({ user, ...accessToken }) => {
        updateUser(user, accessToken);
        Notify.default(userService.getDomainBasedGreeting(user));
        return userService.redirect(user,history);
      })
      .catch(error => Notify.error(format.error(error)));
  };

  return (
    <LayoutPartnerPromo partner={partner} splashBG={`${process.env.REACT_APP_S3_ASSET_URL}${get(landingPage,'side_column_image_url')}`}>

      <Helmet>
        <title>Welcome to { get(partner,'name') ? `${get(partner,'name')} :` : '' } Moved</title>
      </Helmet>

      <div className={CSS.wrapper}>
        <h1 className={CSS.title}>
          { format.capitalize(get(tokenUser,'firstname'))}, great news!
        </h1>
        { coupon ? (<>
          <p className={CSS.subtitle}>
            {get(partner,'name')} is providing you with a <strong className='black'>{ format.currency(coupon_amount,{maximumFractionDigits:0}) }</strong> move
            coupon and a concierge service, provided by Moved, to make your upcoming
            move stress-free.
          </p>
          <CouponTicket
            coupon={coupon}
            title={format.address(location,{ zipcode: false })}
            cta={'Redeem my coupon'}
            onClick={onSubmit}
          />
          <div className={CSS.footer}>
            *Coupon amount based on size of unit rented at {get(partner,'name')}. Offer valid with rental and cannot but combined with any other promotion.
          </div>
        </>) : (<>
          <ContentRenderer content={get(landingPage,'content')} className={CSS.subtitle} />
          <label
            role="button"
            className={CSS.button}
            tabIndex="0"
            onClick={onSubmit}
          >
            Let's get started
            <Icon symbol={'Arrow-right'} library={'navigation'} size={'24px'} color={'white'} className={CSS.arrow} />
          </label>

        </>)}
      </div>

    </LayoutPartnerPromo>
  );
};
