import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

export const Addresses = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const move = taskDefinition.selectors.useMove(moveId);

  const getMoveStepLeaseAddress = (type) => {
    const leaseAddress = (move?.move_steps ?? []).find(step => step.type === type)?.lease?.address;
    return leaseAddress?.google_place_id && { type: taskDefinition.isStorage ? 'storage' : 'apartment', address: leaseAddress };
  };
  const getTenantPriorAddress = () => {
    const tenantAddress = (move?.move_steps ?? []).find(step => step.type === 'move-in')?.tenant?.prior_address;
    return tenantAddress?.google_place_id && { address: tenantAddress };
  };
  const getMoveOnboardingAddress = (type) => {
    const onboardingAddress = move?.[`${type}_address`];
    return onboardingAddress?.google_place_id && { address: onboardingAddress };
  };

  let initialStops = moverBooking?.stops?.map?.(stop => stop?.address?.google_place_id ? stop : {}) ?? [];

  if(initialStops?.length < 2) {
    const initialOrigin = (
      getMoveStepLeaseAddress('move-out') ?? // prepopulated from the lease data
      getTenantPriorAddress() ?? // prepopulated from the tenant data
      getMoveOnboardingAddress('origin') // prepopulated from the move's onboarding data
    );
    const initialDestination = (
      getMoveStepLeaseAddress('move-in') ?? // prepopulated from the lease data
      getMoveOnboardingAddress('destination') // prepopulated from the move's onboarding data
    );
    initialStops = [
      initialOrigin ?? {},
      initialDestination ?? {},
    ];
  }

  const fields = [
    {
      label: 'Addresses',
      type: 'addressList',
      name: 'stops',
      value: initialStops,
      required: 'Address is required',
    },
  ];

  const submit = data => {
    setPending(true);
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, data))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => {
        setPending(false);
        Notify.error(format.error(err));
      });
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <DynamicForm
          id='address-form'
          fields={fields}
          formStyle='underline'
          onSubmit={submit}
          disabled={pending}
        />
      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor='address-form-submit'
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Next
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
}
