import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useNotify, useUser, userService } from '@moved/services';
import { LayoutPromo, SigninForm } from '@moved/product';

import { useBuilding } from '../../common/actions/selectors';
import { SignupForm } from './SignupForm'
import { DirectSignupForm } from './DirectSignupForm';

import CSS from '../styles/Authentication.module.scss';

export const Authentication = (props) => {
  const { buildingSlug, view } = useParams();
  const history = useHistory();
  const { user, hasDomain } = useUser();
  const Notify = useNotify();

  // redux
  const building = useBuilding(buildingSlug);
  const FormComponent = (view === 'signin') ?
    SigninForm :
    ((buildingSlug && building) ?
      SignupForm :
      DirectSignupForm
    );

  const toggleDisplay = () => {
    history.push((view === 'signup' ? '/signin' : '/signup') + (buildingSlug ? `/${buildingSlug}` : ''));
  };

  useEffect(() => {
    if(!hasDomain('guest')) {
      Notify.default(userService.getDomainBasedGreeting(user));
      userService.redirect(user,history);
    }
  },[]); // eslint-disable-line

  const onComplete = ({ move_step_id, move_id, login_response }) => {
    Notify.default(userService.getDomainBasedGreeting(get(login_response,'user')));
    if(move_id) history.push(`/moves/${move_id}`+(move_step_id ? `/steps/${move_step_id}` : ''));
    else userService.redirect(get(login_response,'user'),history);
  };

  return (
    <LayoutPromo building={buildingSlug && building} mobileHeader={
      <div className={CSS.form_toggle} onClick={toggleDisplay}>
        { view === 'signup' ? 'Sign in' : 'Sign up' }
      </div>
    }>
      <Helmet>
        <title>Welcome to { buildingSlug ? `${get(building, 'settings.display_name', '')} :` : '' } Moved</title>
      </Helmet>

      <div className={CSS.view_toggle}>
        { view === 'signup' ?
          'Already registered?' :
          'Don\'t have an account?'
        }
        <span className="faux-link ml-5" onClick={toggleDisplay}>
          { view === 'signup' ? 'Sign in' : 'Sign up' }
        </span>
      </div>

      <div className={CSS.form_wrapper}>
        <FormComponent
          building={buildingSlug && building}
          onComplete={onComplete}
          title={view === 'signup' ? 'Sign up, then sit back.' : 'Welcome!' }
          subtitle={view === 'signup' ? 'Receive competitive rates from the best professional movers.' : 'Let\'s get you moved.'}
        />
      </div>

    </LayoutPromo>
  );
};
